<template>
  <div class="global-box">
    <div class="nav-box">
      <span class="nav-list" @click="gifChoose(1)" ref="gif1">天津近岸海域流场变化图</span>
      <span class="nav-list" @click="gifChoose(2)" ref="gif2">东疆港口海域流场变化图</span>
      <span class="nav-list" @click="gifChoose(3)" ref="gif3">东疆港口海域垃圾迁移路径图</span>
    </div>
    <div class="main-box">
      <div class="first" v-show="selectedTab === 1">
          <div class="image-box">
            <img src="./images/1-大潮-天津.gif" alt="大潮-天津" class="img1 "/>
            <img src="./images/2-小潮-天津.gif" alt="小潮-天津" class="img1"/>
          </div>
          <div class="text-box">
            天津近岸海域属于渤海湾海域，为正规和不正规半日潮潮汐，平均潮差约2-3米，大潮潮差约4米。
            海浪以风浪为主，流场以往复流为主要特征。大潮期间流速高于小潮期间，远海区域流速高于近岸区域，
            流场特征不利于物质在近岸低流速区域输运与扩散。
          </div>
      </div>
      <div class="second" v-show="selectedTab === 2">
        <div class="image-box">
          <img src="./images/3-大潮-东疆.gif" alt="大潮-东疆" class="img2"/>
          <img src="./images/4-小潮-东疆.gif" alt="小潮-东疆" class="img2"/>
        </div>
        <div class="text-box">
          东疆港区近岸海域流速较低，仅在东疆港沙滩公园堤口与天津港航道口门处有高流速区域。
          低流速区主要为东堤最北侧与防波堤夹角处、东堤最南侧与防波堤夹角处以及内部航道靠陆区域。
          东堤沿岸存在浅滩区域，海洋垃圾飘至该区域后易搁浅存积。
        </div>
      </div>
      <div class="third" v-show="selectedTab === 3">
        <div class="left">
          <a-select
              v-model:value="riverValue"
              style="width: 360px"
              @change="handleChange"
              class="river-select"
          >
            <a-select-option value="河口1——天津北部永定新河河口垃圾迁移路径">
              河口1——天津北部永定新河河口垃圾迁移路径
            </a-select-option>
            <a-select-option value="河口2——天津中部海河河口垃圾迁移路径">
              河口2——天津中部海河河口垃圾迁移路径
            </a-select-option>
            <a-select-option value="河口3——渤海湾湾顶河口区域垃圾迁移路径">
              河口3——渤海湾湾顶河口区域垃圾迁移路径
            </a-select-option>
            <a-select-option value="河口4——天津南部独流减河河口区域垃圾迁移路径">
              河口4——天津南部独流减河河口区域垃圾迁移路径
            </a-select-option>
            <a-select-option value="河口5——东疆东侧近岸海域垃圾迁移路径">
              河口5——东疆东侧近岸海域垃圾迁移路径
            </a-select-option>
          </a-select>
          <div class="image-box">
            <img :src="selectedImg" alt="选择的河口预测图" style="width: 60%">
          </div>
        </div>
        <div class="right">
          <div class="images-box">
            <img src="./images/河口-1.gif">
            <img src="./images/河口-2.gif">
            <img src="./images/河口-3.gif">
            <img src="./images/河口-4.gif">
            <img src="./images/河口-5.gif">
          </div>
          <div class="text-box">
            天津东疆附近海域主要河口及海上垃圾迁移路径模拟结果显示，北部永定新河河口与中部海河河口处垃
            圾源对东疆垃圾来源影响最大，永定新河河口主要对东疆北侧及东侧滩涂处有影响，海河河口主要对东
            疆西侧港池处有影响；渤海湾湾顶河口区域及东疆东侧近岸海域对东疆垃圾来源影响次之，主要对东疆
            东侧滩涂处有影响；天津南部河流对东疆垃圾来源影响相对较小。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      selectedTab: 1,
      riverValue: "河口1——天津北部永定新河河口垃圾迁移路径",
      selectedImg: "",

    };
  },
  methods: {
    handleChange() {
      switch (this.riverValue) {
        case "河口1——天津北部永定新河河口垃圾迁移路径":
          this.selectedImg = require("./images/河口-1.gif");
          break;
        case "河口2——天津中部海河河口垃圾迁移路径":
          this.selectedImg = require("./images/河口-2.gif");
          break;
        case "河口3——渤海湾湾顶河口区域垃圾迁移路径":
          this.selectedImg = require("./images/河口-3.gif");
          break;
        case "河口4——天津南部独流减河河口区域垃圾迁移路径":
          this.selectedImg = require("./images/河口-4.gif");
          break;
        default:
          this.selectedImg =require("./images/河口-5.gif");
          break;
      }
    },
    gifChoose(index) {
      switch (index) {
        case 1:
          this.selectedTab = 1;
          this.$refs.gif1.style.color = "#fff";
          this.$refs.gif1.style.background = "#2dc912";
          this.$refs.gif2.style.color = "#151517";
          this.$refs.gif2.style.background = "#fff";
          this.$refs.gif3.style.color = "#151517";
          this.$refs.gif3.style.background = "#fff";
          break;
        case 2:
          this.selectedTab = 2;
          this.$refs.gif2.style.color = "#fff";
          this.$refs.gif2.style.background = "#2dc912";
          this.$refs.gif1.style.color = "#151517";
          this.$refs.gif1.style.background = "#fff";
          this.$refs.gif3.style.color = "#151517";
          this.$refs.gif3.style.background = "#fff";
          break;
        case 3:
          this.selectedTab = 3;
          this.$refs.gif3.style.color = "#fff";
          this.$refs.gif3.style.background = "#2dc912";
          this.$refs.gif2.style.color = "#151517";
          this.$refs.gif2.style.background = "#fff";
          this.$refs.gif1.style.color = "#151517";
          this.$refs.gif1.style.background = "#fff";
          break;
      }
    }
  },
  mounted() {
    this.handleChange();
  }
});
</script>

<style lang="scss" scoped>
.global-box {
  width: 100%;
  height: 100%;
  background: #FFF;
  .nav-box {
    width: 48%;
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .nav-list {
      display: block;
      width: 30%;
      height: 40px;
      margin-right: 10px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.2);
      color: #151517;
      font-size: 16px;
      font-weight: 550;
      text-align: center;
      line-height: 40px;
      user-select: none;
    }
    .nav-list:first-child {
      background: #2dc912;
      color: #fff;
    }
    .nav-list:hover {
      background: #2dc912;
      color: #fff;
      cursor: pointer;
    }
  }
  .main-box {
    width: 100%;
    .image-box {
      background-color: #FFF;
      padding: 8px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .img1 {
        width: 36%;
      }
      .img2 {
        width: 31%;
      }
    }
    .text-box {
      padding: 10px;
      width: 80%;
      font-size: 16px;
      background: rgba(24,144,255,0.4);
      border: 1px solid rgba(24,144,255,0.4);
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      margin: 15px auto;
      text-indent: 2em;
    }
    .third {
      display: flex;
      .left {
        margin-top: 20px;
        width: 50%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .river-select {
          margin-bottom: 10px;
        }
      }
      .right {
        margin-top: 20px;
        width: 50%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .images-box {
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-evenly;
          img {
            width: 30%;
          }
        }
      }
    }
  }
}
</style>